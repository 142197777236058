import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Layout } from '../../Layout';

import * as S from '../../styles/pages/Lacamara.styles';

const Lacamara = () => {
  return (
    <Layout title="La Cámara">
      <S.Container>
        <p>
          La cámara fotográfica es el foco principal de nuestra investigación.
          La que hemos reconstruido rigurosamente desde su proceso mecánico y
          químico, para realizar una réplica fidedigna de la experiencia de
          documentar un paisaje inhóspito y hostil en la región de magallanes a
          través del ojo de una cámara de placas. La técnica utilizada es
          Gelatino de Bromuro, la misma utilizada por el misionero Martín
          Gusinde hace 100 años.
        </p>

        <hr />

        {/* <StaticImage
          src="../../images/lacamara/1.jpg"
          alt="imagen"
          placeholder="blurred"
        /> */}

        <StaticImage
          src="../../images/lacamara/2.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/lacamara/3.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <hr />

        <StaticImage
          src="../../images/lacamara/4.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <hr />

        <StaticImage
          src="../../images/lacamara/5.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <hr />

        <p>
          Realizaremos una expedición fotográfica por la Región de Magallanes,
          siguiendo los pasos de Gusinde con el fin de obtener un nuevo registro
          fotográfico, audiovisual y sociocultural de su ruta. La Ruta comenzar
          en Santiago con dirección a Punta Arenas y sus alrededores, para luego
          navegar a través del Estrecho de Magallanes hacia Porvenir, Tierra del
          Fuego, recorriendo el parque Karukinka, y finalizando en Puerto
          Williams, y vía marítima a través del canal del Beagle.
        </p>
      </S.Container>
    </Layout>
  );
};

export default Lacamara;
